import React from "react"

import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import "../style/index.css"

import footerBg from "../images/contact-background.jpg"

const Div = styled.div`
  width: 95%;
  margin: 0px auto;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  background: url(${footerBg});
  background-position: bottom;
  background-size: cover;
  min-height: 700px;
  position: relative;
  margin-bottom: 200px;
`
const InnerDiv = styled.div`
  width: 80%;
  min-width: 320px;
  background: #fff;
  padding: 20px;
  margin: 0px auto;
  position: absolute;
  top: 70%;
  left: 0px;
  box-shadow: 0px 20px 50px #ddd;
  right: 0px;
  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .col {
      width: 31%;
      min-width: 200px;
      margin: 1px;
      padding: 10px;
      h1 {
        font-weight: bold;
        font-size: 1.2em;
      }
      &:last-child {
        margin-top: 22px;
      }
      p {
        background: #eaeaea;
        margin: 10px 0px;
        font-size: 1em;
        line-height: 25px;
        padding: 10px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 15px;
        }
        h6 {
          font-size: 0.6em;
          font-weight: bold;
        }
        h5 {
          font-size: 0.8em;
        }
        h4 {
          font-size: 1em;
        }
      }
    }
  }
`

const ContactUs = () => (
  <Layout showOnlyHome={true}>
    <SEO title="Contact Us" />
    <Div>
      <InnerDiv>
        <div class="container">
          <div class="col text-center">
            <h1>Corporate Office</h1>
            <p>IMADOL LALITPUR </p>
            <p>PHONE: +977-1-5582492, 5582592 </p>
            <p>DIRECT LINE: +977-9802007810, 9801014640 FAX: +977-1-5582492</p>
          </div>
          <div class="col">
            <h1>Contact Person</h1>
            <p>
              <h3>BASANTA ROKKA CHHETRI (CHAIRMAN)</h3>
              <h5>+977-9851017603, 980207603, 9857015803 (Whatsapp & Viber)</h5>
              <h5>
                E-Mail:- basanta@kasthamandal.com.np
                basanta.rokachhetri@yahoo.com
              </h5>
            </p>
            <p>
              <h3>KUMAR ROKKA CHHETRI (DIRECTOR)</h3>
              <h5>+977 – 9851023687, 9801023687 (Whatsapp & Viber)</h5>
              <h5>E-Mail:- kumarrc4@gmail.com, kumarrc@gajurmukhiherbal.com</h5>
            </p>
            <p>
              <h3>DEEPAK ROKKA (DIRECTOR)</h3>
              <h5>+977 - 9851105748, 9801105748 (Whatsapp &amp; Viber)</h5>
              <h5>
                E-Mail:- deepakrc@gajurmukhiherbal.com, deepak.rc27@gmail.com
              </h5>
            </p>
          </div>
          <div class="col">
            <p>
              <h3> BHARAT BDR ROKKA CHHETRI (DIRECTOR)</h3>
              <h5> +977 – 9855067280, 9821170980</h5>
              <h5>E-Mail:- bharat@kasthamandal.com.np</h5>
            </p>
            <p>
              <h3> ISHOWAR BDR. ROKKA CHHETRI (DIRECTOR)</h3>
              <h5> +977 – 9851079658, 9801079658</h5>
              <h5>E-Mail:- Ishowar@kasthamandal.com.np</h5>
            </p>
            <p>
              <h3> Aakash rokka chhetri (MARKETING EXCUTIVE)</h3>
              <h5>+977 – 9851220090, 9801320090 (Whatsapp &amp; Viber)</h5>
              <h5>E-Mail:- aakash@kasthamandal.com.np</h5>
            </p>
          </div>
        </div>
      </InnerDiv>
    </Div>
  </Layout>
)

export default ContactUs
